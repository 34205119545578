<template>
    <div>
        <full-overlay :show="loading" />
        <b-button-group>
            <b-button variant="success" @click="add">Добавить</b-button>
            <b-button variant="primary" @click="getList(1)">Обновить</b-button>
        </b-button-group>
        <div class="area mt-3">
            <b-card v-for="l in list" :key="l.id">
                <b-card-title>{{ l.id }}</b-card-title>
                <b-card-text>Скрыт: {{ l.hidden ? "Да" : "Нет" }}</b-card-text>
                <b-button-group class="w-100">
                    <b-button @click="edit(l)">
                        <b-icon icon="pencil" />
                    </b-button>
                    <b-button @click="content(l.id)">
                        <b-icon icon="list" />
                    </b-button>
                    <b-button
                        :to="{ name: 'FestivalCard', params: { id: l.id } }"
                    >
                        <b-icon icon="link" />
                    </b-button>
                </b-button-group>
            </b-card>
        </div>
        <b-modal
            title="Редактирование"
            v-model="modal.show"
            hide-footer
            size="xl"
        >
            <b-form @submit.prevent="send">
                <b-form-group label="Год проведения">
                    <b-form-input type="number" v-model="modal.id" required />
                </b-form-group>
                <b-form-group>
                    <b-form-checkbox v-model="modal.hidden">
                        Скрыт
                    </b-form-checkbox>
                </b-form-group>
                <b-form-group label="Загрузка постера">
                    <b-form-file
                        v-model="modal.poster"
                        accept="image/jpeg, image/png"
                        :required="modal.add"
                    />
                </b-form-group>
                <div class="poster" v-if="!modal.add">
                    <img
                        :src="`${poster}/${modal.id}/poster.jpg?${Date.now()}`"
                    />
                </div>
                <b-button type="submit" class="mt-1">Сохранить</b-button>
            </b-form>
        </b-modal>
        <content-editor
            ref="editor"
            get-url="/festival/:id/content"
            send-url="/festival/:id/content"
            :media-url="poster"
        />
    </div>
</template> 

<style lang="scss" scoped>
.area {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
}
.poster {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    img {
        max-width: 700px;
        max-height: 600px;
    }
}
</style>

<script>
import FullOverlay from "@c/FullOverlay";
import ContentEditor from "@c/ContentEditor";
export default {
    name: "Festival",
    components: {
        FullOverlay,
        ContentEditor,
    },
    data() {
        return {
            list: [],
            loading: false,
            modal: this.getModal(),
            poster: "",
        };
    },
    mounted() {
        this.getList();
    },
    methods: {
        getModal: () => ({
            id: "",
            hidden: false,
            poster: null,
            show: false,
            add: false,
        }),
        async add() {
            this.modal = this.getModal();
            this.modal.add = true;
            this.modal.show = true;
        },
        edit(l) {
            this.modal = Object.assign(this.getModal(), l);
            this.modal.show = true;
        },
        async send() {
            this.loading = true;
            const body = new FormData();
            body.append("id", this.modal.id);
            body.append("hidden", this.modal.hidden ? 1 : 0);
            if (this.modal.poster) {
                body.append("poster", this.modal.poster);
            }
            await this.$fetchAdmin("/festival/save", {
                method: "POST",
                body,
            }).then((r) => r.json());
            this.loading = false;
            this.modal.show = false;
            this.getList();
        },
        async getList() {
            this.loading = true;
            const { list, poster } = await this.$fetchAdmin(
                `/festival`
            ).then((r) => r.json());
            this.list = list;
            this.poster = `${this.$store.state.api}${poster}`;
            this.loading = false;
        },
        content(id) {
            this.$refs.editor.open(id);
        },
    },
};
</script>